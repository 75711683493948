import global from '../global';
import {showError} from "../utils/helper";


export const printReceipt = (data) => {
    console.log("print invoice: ")
    //console.log(data)

    var LF = "\n";
    var issueID = 1;
    var tmp = "123456789"
    var _inch = 3;
    var p_name = "Printer1"
    var p_result = ""

    function viewResult(result) {
        p_result = result;
        if(result != "") {
            console.log(result)
            if(!result.includes("success")) {
                showError('Došlo je do greške prilikom ispisa računa! Error: ' + result)
            }
        }

    }

    window.setPosId(issueID);
    window.checkPrinterStatus();
    var invoiceLogo = localStorage.getItem('invoiceLogo');
    //console.log(global.invoiceDevice)
    //console.log(invoiceLogo)

    //var imageFileWindows = "C:\\pos\\logo.png";
    //printBitmapFile(imageFileWindows,-2,1,false);
    if (invoiceLogo) {
        window.printBitmap(invoiceLogo, -2, 1, false);
    }

    window.printText("\n" + global.invoiceDevice.invoice_space_company, 0, 0, true, false, false, 0, 1);

    window.printText("\n" + global.invoiceDevice.invoice_space_address + ", " + global.invoiceDevice.invoice_space_zip + ", " + global.invoiceDevice.invoice_space_city, 0, 0, false, false, false, 0, 1);
    window.printText("\nOIB: " + global.invoiceDevice.invoice_space_oib, 0, 0, false, false, false, 0, 1);
    window.printText("\nBroj telefona: " + global.invoiceDevice.invoice_space_phone, 0, 0, false, false, false, 0, 1);
    window.printText("\nEmail: " + global.invoiceDevice.invoice_space_email, 0, 0, false, false, false, 0, 1);
    window.printText("\n" + global.invoiceDevice.invoice_space_web + "\n\n", 0, 0, false, false, false, 0, 1);

    window.printText("Artikl               Kol.  Cijena    Iznos\n", 0, 0, false, false, false, 0, 0);
    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);

    data.invoice_items.map(invoice_item => {
        window.printText(invoice_item.item_name + "\n", 0, 0, false, false, false, 0, 0);
        window.printText("                 " + invoice_item.item_quantity.padStart(6, " ") + "    " + invoice_item.item_price_with_tax.padStart(6, " ") + "   " + invoice_item.total_item_price_with_tax.padStart(6, " ") + "\n", 0, 0, false, false, false, 0, 0);

    })


    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);

    if (data.invoice_total_discounts_with_tax != "0,00") {
        window.printText("Popust                " + data.invoice_total_discounts_with_tax.padStart(20, " ") + "\n", 0, 0, false, false, false, 0, 0);
        window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);
    }

    window.printText("Ukupno              " + data.invoice_total_with_tax.padStart(20, " ") + " " + data.invoice_currency_symbol + "\n", 0, 1, true, false, false, 0, 0);
    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);
    window.printText("Način plaćanja: " + data.invoice_payment_method + "\n", 0, 0, false, false, false, 0, 0);
    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);
    window.printText("Porez          %       Osnovica      Iznos\n", 0, 0, false, false, false, 0, 0);
    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);

    data.invoice_tax_rates.map(tax_rate => {
        window.printText("PDV            " + tax_rate.Stopa + "     " + tax_rate.Osnovica.padStart(7, " ") + "      " + tax_rate.Iznos.padStart(7, " ") + "\n", 0, 0, false, false, false, 0, 0);
    })

    window.printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);

    window.printText("\n\n" + global.invoiceDevice.invoice_title + " " + data.invoice_real_id, 0, 0, false, false, false, 0, 0);
    window.printText("\nVrijeme računa: " + data.invoice_date, 0, 0, false, false, false, 0, 0);
    window.printText("\nOznaka blagajnika: " + data.admin_device_name, 0, 0, false, false, false, 0, 0);
    window.printText("\nZKI: " + data.invoice_zki, 0, 0, false, false, false, 0, 0);
    window.printText("\nJIR: " + data.invoice_jir + "\n\n", 0, 0, false, false, false, 0, 0);

    window.printQRCode(data.invoice_fiskal_qr_link, 0, 1, 7, 0);
    window.printText("\n\n\n\n", 0, 0, false, false, false, 0, 0);
    window.printText("Zahvaljujemo na ukazanom povjernju i vidimo se opet!!!", 0, 0, false, false, false, 0, 1);
    window.printText("\n\n", 0, 0, false, false, false, 0, 0);

    window.cutPaper(1);

    var strSubmit = window.getPosData();

    //console.log(strSubmit);

    window.requestPrint(p_name, strSubmit, viewResult);

    return true;
}